<template>
  <div class="app-wrapper">

    <Navbar />
    <AppMain ref="appMain" />
    <Footer v-if="tabbarShow" />
    <!-- 15 -->
  </div>
</template>

<script>
// import RightPanel from '@/components/RightPanel'
import tabbar from "@/utils/tabbar";
import { Navbar, AppMain, Footer } from "./components";

export default {
  name: "Layout",
  components: {
    AppMain,
    Footer,
    Navbar,
  },
  computed: {
    tabbarShow: function () {
      let _bool = tabbar.includes(this.$route.name);
      return _bool;
    },
  },
  methods: {
    tabbarIconEvent(iconEvent, index) {
      if (iconEvent == 'addWarehousing' || iconEvent == 'addLocation' || iconEvent == 'exportFn' || iconEvent == 'Inventory') {
        this.$nextTick(() => {
          let _ind = this.$refs.appMain.$children.length - 1;
          let _ind_TWO = this.$refs.appMain.$children[_ind].$children.length - 1;
          // console.log(_ind, _ind_TWO, this.$refs.appMain.$children[_ind].$children[_ind_TWO], '看看那');
          this.$refs.appMain.$children[_ind].$children[_ind_TWO][iconEvent](index);
        });
      } else {
        this.$nextTick(() => {
          let _ind = this.$refs.appMain.$children.length - 1;
          this.$refs.appMain.$children[_ind][iconEvent](index);
        });

      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
// .appHomePage {
//   // height: $childPageHeight;
//   // height: $homePageHeight;
//   // height: $mainPageHeight;
//   // overflow-x: auto;
//   // background: #fff;
// }</style>